/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

type Keys = 'heading' | 'windowTitle' | 'filterWindowTitle';

type Messages = Record<Keys, MessageDescriptor>;

const messages: Messages = defineMessages({
	heading: {
		id: 'global-issue-navigator.heading',
		defaultMessage: 'Issues',
		description: 'Page heading title',
	},
	windowTitle: {
		id: 'global-issue-navigator.window-title-migration',
		defaultMessage: 'Issue navigator',
		description: 'Window title of the global issue navigator page',
	},
	filterWindowTitle: {
		id: 'global-issue-navigator.filter-window-title-migration',
		defaultMessage: '[{filterName}] Issue navigator',
		description: 'Window title of the global issue navigator page when filter is applied',
	},
});

export default messages;
