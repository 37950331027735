/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { AppSkeletonImage } from '../../../common/ui';
import imgSVG from '../assets/global-issue-navigator-header-skeleton.tpl.svg';

const customCssStyles = css({
	// increase self specificity to ensure deterministic override
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		border: 'none',
	},
});

const containerStyles = { height: '80px' } as const;

export const GlobalIssueNavigatorHeaderSkeleton = () => (
	<AppSkeletonImage src={imgSVG} css={customCssStyles} containerStyles={containerStyles} />
);
