import React, { type ReactNode, useCallback, useEffect, useMemo } from 'react';
import { parse as fromQueryString, stringify as toQueryString } from 'query-string';
import { Text } from '@atlaskit/primitives';
import { toFlagId, useFlagsService } from '@atlassian/jira-flags';
import type { AsyncFlagProps } from '@atlassian/jira-flags/src/common/ui/components';
import InfoFlag from '@atlassian/jira-flags/src/common/ui/components/info-flag';
import type { FlagCustomRenderProps } from '@atlassian/jira-flags/src/services/types';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useRouter } from '@atlassian/react-resource-router';
import { useBooleanPreferenceSet } from '../../services/use-boolean-preference-set';
import { useSmartQueryCheck } from '../../services/use-smart-query-check';
import { SMART_QUERY_DETECTED_FLAG_ID } from './constants';
import messages from './messages';

type ActionType = {
	content: ReactNode;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
	href?: string;
	target?: string;
	testId?: string;
};

const flagId = toFlagId(SMART_QUERY_DETECTED_FLAG_ID);

export const Flag = (props: FlagCustomRenderProps & AsyncFlagProps) => {
	const { dismissFlag } = useFlagsService();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onDismissed = () => {
		dismissFlag(flagId);
		fireTrackAnalytics(createAnalyticsEvent({}), 'smartQueryDetectedFlag dismissed');
	};

	return <InfoFlag {...props} onDismissed={onDismissed} />;
};

function useDontShowAgainAction(): { dontShowAgainAction: ActionType } {
	const { update: updateSmartQueryDontShowPreference } = useBooleanPreferenceSet(
		'user.smart.query.flag.dont.show',
	);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { dismissFlag } = useFlagsService();
	const { formatMessage } = useIntl();

	return {
		dontShowAgainAction: useMemo(
			() => ({
				content: formatMessage(messages.flagDontShowAgainAction),
				onClick: () => {
					updateSmartQueryDontShowPreference();
					fireTrackAnalytics(
						createAnalyticsEvent({}),
						'smartQueryDetectedFlagDontShowAgain clicked',
					);
					dismissFlag(flagId);
				},
			}),
			[formatMessage, updateSmartQueryDontShowPreference, createAnalyticsEvent, dismissFlag],
		),
	};
}

export const useSmartQueryFlag = () => {
	const { showFlag, hasFlag } = useFlagsService();
	const { formatMessage } = useIntl();
	const { dontShowAgainAction } = useDontShowAgainAction();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const flagActions = useMemo(() => [dontShowAgainAction], [dontShowAgainAction]);
	const displayFlag = useCallback(() => {
		if (!hasFlag?.(flagId)) {
			showFlag({
				id: flagId,
				render: (props: FlagCustomRenderProps) => (
					<Flag
						{...props}
						id={flagId}
						key={flagId}
						title={formatMessage(messages.flagTitle)}
						description={formatMessage(messages.flagDescription, {
							strong: (chunks: ReactNode[]) => <Text as="strong">{chunks}</Text>,
						})}
						actions={flagActions}
						testId="issue-navigator-flags.ui.smart-query-detected-flag.smart-query-detected-flag"
					/>
				),
			});
			fireUIAnalytics(createAnalyticsEvent({}), 'smartQueryDetectedFlag shown');
		}
	}, [hasFlag, showFlag, formatMessage, flagActions, createAnalyticsEvent]);
	return { displayFlag };
};

export const SmartQueryDetectedFlag = ({ jql }: { jql?: string }) => {
	const { displayFlag } = useSmartQueryFlag();
	const [route, { replace }] = useRouter();
	const params = useMemo(() => fromQueryString(route.location.search), [route.location.search]);
	const wildcardAddedBool = params.wildcardAdded === 'true';
	const smartQueryDisabledBool = params.smartQueryDisabled === 'true';
	const { isSmartQuery, data } = useSmartQueryCheck({
		jql: smartQueryDisabledBool ? jql : undefined,
		wildcardAdded: wildcardAddedBool,
	});

	useEffect(() => {
		if (isSmartQuery) {
			displayFlag();
		}
	}, [isSmartQuery, displayFlag]);

	// detection check should will remove smartQueryDisabled and wildcardAdded from the search params, making sure we don't show the flag again
	useEffect(() => {
		// if smart query is not disabled, still need to get rid of wildcardAdded param
		if (data || (!smartQueryDisabledBool && wildcardAddedBool)) {
			// remove smartQueryDisabled and wildcardAdded from the search params, including any & at the start
			// using fromQueryString here so that it encodes the JQL in the same format everything else does
			const newSearch = { ...params };
			if (newSearch.smartQueryDisabled) {
				delete newSearch.smartQueryDisabled;
			}
			if (newSearch.wildcardAdded) {
				delete newSearch.wildcardAdded;
			}

			// @ts-expect-error - The "comma" option requires query-string v6.4.0 or newer. JFE currently uses v5.1.1.
			const newSearchString = `?${toQueryString(newSearch, { arrayFormat: 'comma' })}`;
			// @ts-expect-error - The "comma" option requires query-string v6.4.0 or newer. JFE currently uses v5.1.1.
			const oldSearchString = `?${toQueryString(params, { arrayFormat: 'comma' })}`;
			if (newSearchString !== oldSearchString) {
				replace({
					...route.location,
					search: newSearchString,
				});
			}
		}
	}, [data, route, replace, smartQueryDisabledBool, wildcardAddedBool, params]);

	return null;
};

export default SmartQueryDetectedFlag;
